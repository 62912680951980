<template>
  <!--
    Chama o método downloadFile no $parent

    @event downloadFile
    @property motivo - O motivo para o download.
  -->
  <form @submit.prevent="$emit('downloadFile', { motivo: motivo })" id="clientsDownload">
    <header>
      <h3>Baixar backup {{ clientRecord.file.record_file_name }}</h3>
    </header>
    <div class="content grid">
      <span id="aviso">
        AVISO:

        Este arquivo contém todas as informações cadastradas no sistema de origem, incluindo dados pessoais sensíveis.

        Todos os downloads destes arquivos são registrados neste sistema para auditoria. Por favor, escreva abaixo o motivo pelo qual deseja realizar o download deste arquivo.

        Ao prosseguir com o download, você está declarando que irá empreender todos os esforços necessários para proteger as informações que terá acesso e que tem conhecimento das responsabilidades definidas na LGPD, Lei Nº 13.709, de 14 de agosto de 2018.
      </span>
      <b-field
        label="Motivo" id="motivo">
        <b-input
          placeholder="Motivo para o download"
          v-model="motivo" minlength="5" required>
        </b-input>
      </b-field>
      <!--
        Change the create on the $parent, closing the create form
        @event cancel
      -->
      <button @click="$emit('cancel')" class="__error" id="cancel">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Continuar</button>
      <span v-if="baixando" id="status">Baixando... {{ progresso }}%</span>
      <span v-if="baixou" id="status">Download concluído! Aguarde...</span>
    </div>
  </form>
</template>

<script>
/**
 * Solicita o motivo para o download.
 * @displayName Download
 */
export default {
  name: 'clientsDownload',
  props: {
    clientRecord: {
      type: Object,
      required: true
    },
    progresso: {
      required: true
    }
  },
  data () {
    return {
      motivo: null,
      baixando: false,
      baixou: false
    }
  },
  watch: {
    progresso (newVal, oldVal) {
      if (newVal === 100) {
        this.baixando = false
        this.baixou = true
      } else if (newVal > 0) {
        this.baixou = false
        this.baixando = true
      }
    }
  },
  methods: {
  }
}
</script>
