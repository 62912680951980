const actions = {
  /**
   * Communicate with the server to get the user tokens
   *
   * @returns {undefined}
   */
  get ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1] // { dados do formulário }

    // returns a promise
    return new Promise((resolve, reject) => {
      console.log(data)
      that.$http.post(that.$api({
        target: 'developer/forgot-password', // endpoint no back-end
        secure: true, // usar a versão da API
        conn: that.$store.getters.conn // what connection should use
      }), data).then(response => {
        console.log(response)
        if (response.data.error) {
          return false
        } else {
          return true
        }
      }).then(status => {
        resolve(status)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  actions
}
