<template>
  <section class="widget" id="clientsDetails">
    <header class="grid">
      <h3>Registros</h3>
      <span></span>
      <div>
        <b-icon size="is-large" icon="filter"></b-icon>
      </div>
    </header>
    <div class="content">
      <b-table
        :data="clientRecords"
        :paginated="true"
        :per-page="10"
        :hoverable="true"
        :selected.sync="clientRecord">
        <!-- :selected.sync="clientRecord" -->
        <template slot-scope="props">
          <b-table-column field="record_name" label="Backup">
            {{ props.row.file ? props.row.file.record_file_name : '-----' }}
          </b-table-column>
          <b-table-column field="created_at" label="Data">
            {{ props.row.file ? parseDate(props.row.file.written_at) : parseDate(props.row.created_at) }}
          </b-table-column>
          <b-table-column field="created_at" label="Status">
            <span v-for="(test, index) in client.tests" :key="index" class="bars">
              <b-tooltip :label="test.test_type.name" type="is-dark">
                <span v-if="props.row.tests[index]">
                  <span class="icon is-success" v-if="props.row.tests[index].result"></span>
                  <span class="icon is-danger" v-else></span>
                </span>
                <span class="icon is-inactive" v-else></span>
              </b-tooltip>
            </span>
          </b-table-column>
          <b-table-column field="file_size" label="Tamanho">
            {{ props.row.file ? parseFilesize(props.row.file.file_size) : '-----' }}
          </b-table-column>
          <b-table-column v-if="activeDownload()" field="created_at" label="Download">
            <!--
              <b-button v-if="props.row.file && props.row.file.stored" @click="downloadFile(props.row)" class="is-success" icon-left="download"></b-button>
            -->
            <b-button v-if="props.row.file && props.row.file.stored" @click="downloadForm(props.row)" class="is-success" icon-left="download"></b-button>
            <b-tooltip position="is-left" v-else label="Download não disponível" type="is-danger">
              <b-button class="is-danger" icon-left="times"></b-button>
            </b-tooltip>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered" v-if="!fetchet">
              <p>
              <b-icon
                icon="spinner"
                size="is-large">
              </b-icon>
              </p>
              <p>Carregando registros...</p>
            </div>
            <div class="content has-text-grey has-text-centered" v-else>
              <p>
              <b-icon
                icon="exclamation-triangle"
                size="is-large">
              </b-icon>
              </p>
              <p>Cliente sem registros!</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="downloadActive">
      <clients-download
        @downloadFile="downloadFile"
        @cancel="downloadActive = false"
        :clientRecord="clientRecord"
        :progresso="progresso"
      ></clients-download>
    </b-modal>

  </section>
</template>

<script>
import filesize from 'filesize.js'
import dates from '../../../mixins/dates'
import clientsDownload from './d-clients-download.vue'

/**
 * Show details of the client
 * @displayName Client Details
 */
export default {
  name: 'clientsDetails',
  mixins: [dates],
  props: {
    /**
     * Client who's details will be shown
     */
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      downloadActive: false,
      critical: false,
      clientRecord: null,
      clientRecords: [],
      clientRecordIndex: 0,
      fetchet: false,
      progresso: 0
    }
  },
  computed: {
  },
  watch: {
    client (newVal, oldVal) {
      if (!oldVal) {
        this.clientRecords = []
        this.getClientRecords(newVal.id)
      } else {
        if (newVal.id !== oldVal.id) {
          this.clientRecords = []
          this.getClientRecords(newVal.id)
        }
      }
    }
  },
  mounted () {
    if (!this.clientRecords.length) {
      this.getClientRecords(this.client.id)
    }
  },
  methods: {
    /**
     * Retorna se os comandos da interface de clientes devem ser exibidos.
     */
    activeCommands () {
      return this.$parent.activeCommands()
    },
    /**
     * Verifica se o usuário tem permissão para baixar backups
     */
    activeDownload () {
      var clientUser = this.$store.getters['userStore/user'].clientuser
      var canDownload = clientUser ? clientUser.can_download : false
      return (this.activeCommands() || canDownload)
    },
    downloadForm (clientRecord) {
      this.clientRecord = clientRecord
      this.downloadActive = true
    },
    /**
     * Baixa o conteúdo do backup
     *
     * @param {Object} motivo - Motivo do download
     * @public
     */
    downloadFile (motivo) {
      this.$Progress.start()
      this.$http.post(this.$api({
        target: 'client-record-url/' + this.clientRecord.file.id
      }), {
        motivo: motivo.motivo
      }).then(response => {
        window.open(response.data, '_download')
        this.downloadActive = false
      }).then(() => {
      })
    },

    /**
     * Gets the clientRecords for the client and sets them on the component
     *
     * @param {String} id - id of the client
     * @returns {null
     * @public
     */
    getClientRecords (id) {
      this.$Progress.start()
      this.fetchet = false
      this.$http.get(this.$api({
        target: 'client-records/' + id
      })).then(response => {
        this.$Progress.finish()
        this.clientRecords = response.data
        this.fetchet = true
      })
    },
    /**
     * Parse the file size to human readable
     *
     * @param {Number} size
     * @returns {String}
     * @public
     */
    parseFilesize (size) {
      return filesize(size)
    }
  },
  components: {
    clientsDownload
    // iMinus,
    // iCheck,
    // iTimes
    // vProgress
  }
}
</script>
