import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueProgressBar from 'vue-progressbar'

import Buefy from 'buefy'
import axios from '@/config/axios'

import './registerServiceWorker'

import { api } from './config'

Vue.config.productionTip = false
Vue.prototype.$api = api // Setting the default communication function
Vue.prototype.$http = axios

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDateFormatter: date => {
    if (Array.isArray(date)) {
      return date.map(d => {
        return d.toLocaleDateString('pt-br')
      }).join(' - ')
    } else {
      return date.toLocaleDateString('pt-BR')
    }
  },
  defaultDateParser: date => {
    console.log(date)
    // const m = moment(date, 'DD/MM/YYYY')
    // return m.isValid() ? m.toDate() : null
  },
  defaultDayNames: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
  defaultMonthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  defaultDatepickerYearsRange: [-100, 100]
})

Vue.use(VueProgressBar, {
  color: '#189AD0',
  failedColor: '#FF0053',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
