<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    <vue-progress-bar></vue-progress-bar>
    <processing-modal></processing-modal>
    <div id="refresh" v-if="updateExists">
      <p>
      Nova atualização encontrada!
      </p>
      <button @click="refreshApp">
        Atualizar
      </button>
    </div>
  </div>
</template>

<script charset="utf-8">
import { mapGetters } from 'vuex'
import processingModal from './components/processing.vue'
export default {
  name: 'app',
  data () {
    return {
      loaded: false,
      refreshing: false,
      updateExists: false,
      registration: null
    }
  },
  computed: {
    ...mapGetters('applicationStore', [
      'loading'
    ])
  },
  watch: {
    loading (newLoading) {
      if (newLoading === 1) {
        this.$Progress.start()
      } else if (newLoading === -1) {
        this.$Progress.fail()
      } else if (newLoading === 0) {
        this.$Progress.finish()
      }
    }
  },
  created () {
    // This piece of code setup the pwa update system
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  beforeMount () {
    const authTokens = JSON.parse(window.localStorage.getItem('authTokens'))
    // const conn = window.localStorage.getItem('conn')
    // if (conn) this.setConn(conn)

    // Usuários não autenticados só podem acessar as views de login ou reset de senha
    if (authTokens) {
      this.autoLogin(authTokens)
    } else {
      if ((this.$route.name !== 'resetPassword') && (this.$route.name !== 'forgotPassword')) {
        this.$router.push({ name: 'login' })
      }
    }

    // This piece of code deal with routing
    // if (!this.$route.matched.length) {
    //   const target = this.$route.path.split('/').pop()
    //   if (this.$router.options.routes.map(r => r.name).indexOf(target) !== -1) this.$router.push({ name: target, query: this.$route.query })
    //   else this.$router.push({ name: 'home' })
    // }
  },
  methods: {
    autoLogin (tokens) {
      this.$store.dispatch('userStore/get', this).then(status => {
        if (status) {
          if (!this.$route.matched.length) {
            const target = this.$route.path.split('/').pop()
            if (this.$router.options.routes.map(r => r.name).indexOf(target) !== -1) this.$router.push({ name: target, query: this.$route.query })
            else this.$router.push({ name: 'clients' })
          } else {
            if (this.$route.name === 'login') {
              this.$router.push({ name: 'clients' })
            }
          }
        } else {
          this.$store.dispatch('authStore/delete')
          this.$router.push({ name: 'login' })
        }
      })
    },
    showRefreshUI (e) {
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage('skipWaiting')
    }
  },
  components: {
    processingModal
  }
}
</script>

<style lang="scss">
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
  @import "./scss/main.scss";
</style>
