const state = {
  databases: [],
  databaseIndex: 0
}

const getters = {
  databases: () => state.databases,
  database: () => state.databases.length ? state.databases[state.databaseIndex] : null
}

const mutations = {
  SET_DATABASES (state, databases) {
    state.databases = databases
  },
  SET_DATABASE_INDEX (state, index) {
    state.databaseIndex = index
  },
  UPDATE_DATABASE (state, database) {
    state.databases.splice(state.databaseIndex, 1, database)
  }
}

const actions = {
  setDatabase ({ commit }, database) {
    commit('SET_DATABASE', database)
  },
  setDatabaseIndex ({ state, commit }, database) {
    const index = state.databases.findIndex(c => c.id === database.id)
    commit('SET_DATABASE_INDEX', index)
  },
  /**
   * Get all databases and return them
   *
   * @returns Array
   */
  index ({ commit }, that) {
    that.$http.get(that.$api({
      target: 'databases'
    })).then(response => {
      commit('SET_DATABASES', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
    // return new Promise((resolve, reject) => {
    // })
  },
  show ({ commit }, that) {
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'databases/profile/' + that.$store.getters['userStore/user'].id
      })).then(response => {
        console.log(response)
        resolve(response.data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  store ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    return new Promise((resolve, reject) => {
      that.$http.post(that.$api({
        target: `database`
      }), data).then(response => {
        commit('SET_DATABASES', response.data)
        return true
      }).then(status => {
        resolve(status)
        // resolve(status)
      }).catch(err => {
        reject(err)
        return err
        // reject(err)
      })
    })
  },
  update ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$http.put(that.$api({
      target: `database`
    }), data).then(response => {
      commit('UPDATE_DATABASE', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  destroy ({ commit, state }, that) {
    return new Promise((resolve, reject) => {
      that.$http.delete(that.$api({
        target: `database/${state.databases[state.databaseIndex].id}`
      })).then(response => {
        commit('SET_DATABASES', response.data)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
