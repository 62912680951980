const state = {
  connections: [],
  connectionIndex: 0
}

const getters = {
  connections: () => state.connections,
  connection: () => state.connections.length ? state.connections[state.connectionIndex] : null
}

const mutations = {
  SET_DEVELOPERS (state, connections) {
    state.connections = connections
  },
  SET_DEVELOPER_INDEX (state, index) {
    state.connectionIndex = index
  },
  UPDATE_DEVELOPER (state, connection) {
    state.connections.splice(state.connectionIndex, 1, connection)
  }
}

const actions = {
  setDeveloper ({ commit }, connection) {
    commit('SET_DEVELOPER', connection)
  },
  setDeveloperIndex ({ state, commit }, connection) {
    const index = state.connections.findIndex(c => c.id === connection.id)
    commit('SET_DEVELOPER_INDEX', index)
  },
  /**
   * Get all connections and return them
   *
   * @returns Array
   */
  index ({ commit }, that) {
    that.$http.get(that.$api({
      target: 'connections'
    })).then(response => {
      commit('SET_DEVELOPERS', response.data)
      // if (response.data.length) {
      //   commit('SET_DEVELOPER', response.data[0])
      // }
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
    // return new Promise((resolve, reject) => {
    // })
  },
  show ({ commit }, that) {
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'connections/profile/' + that.$store.getters['userStore/user'].id
      })).then(response => {
        resolve(response.data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  store ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$http.post(that.$api({
      target: `connection`
    }), data).then(response => {
      commit('SET_DEVELOPERS', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  update ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$Progress.start()
    that.$http.put(that.$api({
      target: `connection`
    }), data).then(response => {
      commit('UPDATE_DEVELOPER', response.data)
      that.$Progress.finish()
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      that.$Progress.fail()
      return err
      // reject(err)
    })
  },
  delete ({ commit }, that) {
    return that
  },
  check ({ commit }, payload) {
    const that = payload[0]
    const email = payload[1]
    that.$Progress.start()
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'connections/check/' + email
      })).then(response => {
        resolve(response.data)
      }).catch(err => {
        that.$Progress.fail()
        console.log(err)
        reject(err)
      })
    })
  },
  profile ({ commit }, that) {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
