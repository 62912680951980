<template>
  <main class="page" id="clients">
    <clients-list
      @paginate="activeFilters ? where() : get(true)"
      @create="createActive = true"
      @edit="editActive = true"
      @filter="filterActive = !filterActive;"
      @critical="$event ? critical() : restore()"
      @loading="isLoading = $event"
      @clear="restore"
      @find="find"
      :loading="isLoading"
      :clients="clients">
    </clients-list>

    <clients-details
      v-if="client"
      @loading="isLoading = $event"
      :client="client">
    </clients-details>
    <b-modal :active.sync="createActive">
      <clients-create
        @cancel="createActive = false"
        @create="create">
      </clients-create>
    </b-modal>
    <b-modal :active.sync="editActive">
      <clients-edit
        @cancel="editActive = false"
        @update="edit"
        :client="client">
      </clients-edit>
    </b-modal>
    <b-modal :active.sync="filterActive">
      <clients-filter
        @filter="where">
      </clients-filter>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import clientsList from './d-clients/d-clients-list.vue'
import clientsDetails from './/d-clients/d-clients-details.vue'
import clientsCreate from './d-clients/d-clients-create.vue'
import clientsEdit from './d-clients/d-clients-edit.vue'
import clientsFilter from './d-clients/d-clients-filter.vue'

/**
 * Clients
 * @displayName Client View
 */
export default {
  name: 'clients',
  data () {
    return {
      createActive: false,
      editActive: false,
      filterActive: false,
      isLoading: false
    }
  },
  beforeMount () {
    if (this.activeFilters) {
      this.filter(this)
    } else {
      this.setClientMeta(null)
      this.get()
    }
  },
  computed: {
    ...mapGetters('clientStore', [
      'clients',
      'client',
      'meta',
      'filters'
    ]),
    activeFilters () {
      let ret = false
      Object.keys(this.filters).forEach(f => {
        if (this.filters[f].active) ret = true
      })
      return ret
    }
  },
  watch: {
    activeFilters (newValue) {
      if (newValue) {
        this.setClientMeta(null)
      }
    }
  },
  methods: {
    ...mapActions('clientStore', [
      'index',
      'show',
      'store',
      'update',
      'delete',
      'search',
      'filter',
      'setClientMeta'
    ]),
    /**
     * Retorna se os comandos da interface de clientes devem ser exibidos.
     */
    activeCommands () {
      var userRole = this.$store.getters['userStore/user'].role.name
      return userRole === 'admin' || userRole === 'developer'
    },
    /**
     * Get the clients
     *
     * @param {Boolean} paginate - If the pagination is active or not
     * @public
     */
    get (paginate = false) {
      if (this.meta) {
        if (this.meta.current_page < this.meta.last_page) {
          if (!paginate) this.$store.dispatch('applicationStore/setProcessingStart')
          this.index(this).then(status => {
            this.$store.dispatch('applicationStore/setProcessingFinish', true)
          }).catch(err => {
            this.$store.dispatch('applicationStore/setProcessingFinish', false)
            console.log(err)
          })
        }
      } else {
        if (!paginate) this.$store.dispatch('applicationStore/setProcessingStart')
        this.index(this).then(status => {
          this.$store.dispatch('applicationStore/setProcessingFinish', true)
        }).catch(err => {
          this.$store.dispatch('applicationStore/setProcessingFinish', false)
          console.log(err)
        })
      }
    },
    /**
     * Gets called by the create event emited by client-create
     *
     * @public
     */
    where () {
      if (this.meta) {
        if (this.meta.current_page < this.meta.last_page) {
          this.filter(this).then(status => {
            this.filterActive = false
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        this.filter(this).then(status => {
          this.filterActive = false
        }).catch(err => {
          console.log(err)
        })
      }
    },
    /**
     * Gets called by the create event emited by client-create
     *
     * @param {Object} client - The client that will be passed to the store and created on the server
     * @public
     */
    create (client) {
      this.createActive = false
      this.store([this, client])
    },
    /**
     * Gets called by the create event emited by client-create
     *
     * @param {Object} client - The client that will be passed to the store and created on the server
     * @public
     */
    edit (client) {
      this.editActive = false
      this.update([this, client])
    },
    /**
     * Gets called by the create event emited by client-create
     *
     * @param {String} slug - The client that will be passed to the store and created on the server
     * @public
     */
    find (slug) {
      this.search([this, slug])
    },
    /**
     * Restore the default view of the component

     * @public
     */
    restore () {
      this.index(this)
    }
  },
  components: {
    clientsList,
    clientsDetails,
    clientsCreate,
    clientsEdit,
    clientsFilter
  }
}
</script>
