import { client } from '../config/index.js'
const state = {
  tokens: {}
}

const getters = {
  tokens: () => state.tokens
}
const mutations = {
  SET_TOKENS (state, tokens) {
    window.localStorage.setItem('authTokens', JSON.stringify(tokens))
    state.tokens = tokens
  },
  DELETE_TOKENS (state) {
    window.localStorage.removeItem('authTokens')
    state.tokens = {}
  }
}

const actions = {
  /**
   * Communicate with the server to get the user tokens
   *
   * @returns {undefined}
   */
  get ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1] // { username: '', password: '' }

    // Merge the data with the necessary configuration
    Object.assign(data, client(that.$store.getters['applicationStore/conn']), { grant_type: 'password', scope: '' })

    // returns a promise
    return new Promise((resolve, reject) => {
      that.$http.post(that.$api({
        target: 'oauth/token', // the url /api/oauth/tokens
        secure: false, // ignore version
        conn: that.$store.getters.conn // what connection should use
      }), data).then(response => {
        commit('SET_TOKENS', response.data)
        return true
      }).then(status => {
        resolve(status)
      }).catch(err => {
        reject(err)
      })
    })
  },
  delete ({ commit }) {
    commit('DELETE_TOKENS')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
