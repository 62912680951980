<template>
  <header class="header" id="l-header">
    <img src="img/logo_min.png" alt="">
  </header>
</template>

<script>
export default {
  name: 'l-header'
}
</script>
