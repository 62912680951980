import Vue from 'vue'
import Vuex from 'vuex'
import applicationStore from './stores/applicationStore'
import userStore from './stores/userStore'
import clientStore from './stores/clientStore'
import authStore from './stores/authStore'
import developerStore from './stores/developerStore'
import clientuserStore from './stores/clientuserStore'
import serverStore from './stores/serverStore'
import connectionStore from './stores/connectionStore'
import databaseStore from './stores/databaseStore'
import resetPasswordStore from './stores/resetPasswordStore'
import forgotPasswordStore from './stores/forgotPasswordStore'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  modules: {
    applicationStore,
    userStore,
    clientStore,
    authStore,
    developerStore,
    clientuserStore,
    serverStore,
    connectionStore,
    databaseStore,
    resetPasswordStore,
    forgotPasswordStore
  }
})
