const state = {
  conn: 'prod',
  loading: 0,
  processing: {
    active: false,
    color: '#fff'
  }
}

const getters = {
  conn: () => state.conn,
  loading: () => state.loading,
  processing: () => state.processing
}

const mutations = {
  SET_CONN (state, conn) {
    state.conn = conn
    window.localStorage.setItem('conn', conn) // saving the conn as a client configuration
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_PROCESSING_ACTIVE (state, active) {
    state.processing.active = active
  },
  SET_PROCESSING_COLOR (state, color) {
    state.processing.color = color
  }
}

const actions = {
  setConn ({ commit }, conn) {
    commit('SET_CONN', conn)
  },
  setLoading ({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
  setProcessingStart ({ commit }) {
    commit('SET_PROCESSING_ACTIVE', true)
    commit('SET_PROCESSING_COLOR', '#fff')
  },
  setProcessingFinish ({ commit }, success) {
    if (success) {
      commit('SET_PROCESSING_COLOR', '#00DB56')
    } else {
      commit('SET_PROCESSING_COLOR', '#FF0053')
    }
    setTimeout(() => {
      commit('SET_PROCESSING_ACTIVE', false)
    }, 1000)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
