<template>
  <!--
    Call the create method on the $parent

    @event create
    @property {Object} client - The new client to be created via the clientStore
  -->
  <form @submit.prevent="$emit('create', { client: client })" id="clientsCreate">
    <header>
      <h3>Cadastrar cliente</h3>
    </header>
    <div class="content grid">
      <!-- Slug -->
      <b-field
        :type="input.validated === true ? 'is-success' : (input.validated === false ? 'is-danger' : '')"
        :message="input.message"
        label="Slug" id="slug">
        <b-input
          placeholder="Código do cliente"
          v-model="client.slug" required>
        </b-input>
      </b-field>
      <!-- Usuário cliente -->
      <b-field label="Usuário cliente" id="clientuser">
        <b-select v-model="client.clientuserId" placeholder="Selecione um usuário cliente">
          <option value="">- Nenhum -</option>
          <option
            v-for="clientuser in clientusers"
            :value="clientuser.id"
            :key="clientuser.id">
          {{ clientuser.name + ' - ' + clientuser.user.email }}
          </option>
        </b-select>
      </b-field>
      <!-- Servidor -->
      <b-field label="Servidor" id="server">
        <b-select v-model="client.serverId" placeholder="Selecione um servidor">
          <option
            v-for="server in servers"
            :value="server.id"
            :key="server.id">
          {{ server.name + ' - ' + server.version }}
          </option>
        </b-select>
      </b-field>
      <!-- Ativo -->
      <b-field label="Ativo" id="active">
          <b-checkbox v-model="client.active" :true-value="1" :false-value="0"></b-checkbox>
      </b-field>
      <!-- Token -->
      <b-field label="Token" id="token">
        <b-input placeholder="Token de acesso para verificação N5" v-model="client.token"></b-input>
      </b-field>
      <!--
        Change the create on the $parent, closing the create form
        @event cancel
      -->
      <button @click="$emit('cancel')" class="__error" id="cancel">Cancelar</button>
      <button class="__success" id="confirm" type="submit" :disabled="!input.validated">Salvar</button>
    </div>
  </form>
</template>

<script>
import { debounce } from 'lodash'

/**
 * Create a new client
 * @displayName Create Client
 */
export default {
  name: 'clientsCreate',
  data () {
    return {
      client: {
        slug: null,
        backupNumbers: 2,
        active: 1,
        clientuserId: null,
        serverId: null,
        token: null
      },
      input: {
        validated: undefined,
        message: ''
      }
    }
  },
  mounted () {
    this.$store.dispatch('serverStore/index', this)
    this.$store.dispatch('clientuserStore/index', this)
  },
  computed: {
    servers () {
      return this.$store.getters['serverStore/servers']
    },
    clientusers () {
      return this.$store.getters['clientuserStore/clientusers']
    }
  },
  watch: {
    'client.slug': debounce(function (newSlug) {
      if (newSlug && newSlug.length > 2) {
        this.check(newSlug)
      } else {
        this.setInput(false, '')
      }
    }, 500)
  },
  methods: {
    /**
     * Check if that slug is been used or not
     *
     * @param {String} slug -
     * @public
     */
    check (slug) {
      this.$http.get(this.$api({
        target: `client/check/${slug}`
      })).then(response => {
        if (response.data.found) {
          this.input.validated = false
          this.input.message = 'Cliente já cadastrado'
          this.$buefy.toast.open({
            duration: 2500,
            message: `Cliente já cadastrado!`,
            type: 'is-danger'
          })
          this.setInput(false, 'Cliente já cadastrado')
        } else {
          this.setInput(true)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    /**
     * Configure the input state
     *
     * @param {Boolean} validated - If the input has been validated or not
     * @param {String} [message = ''] - Message to be displayed
     * @param {Boolean} [reset = false] - If the input should alse be reseted
     * @public
     */
    setInput (validated, message = '', reset = false) {
      this.input.validated = validated
      this.input.message = message

      if (reset) {
        setTimeout(() => {
          this.input.validated = undefined
          this.input.message = ''
          this.client.slug = null
        }, 2500)
      }
    }
  }
}
</script>
