<template>
  <!--
    Trigger the update function on the parent to update the client
    @event update
    @property {Object} client - The new client object to be commited to the server via the clientStore
  -->
  <form class="" @submit.prevent="$emit('update', { id: client.id, slug, tests, active, serverId, clientuserId, token, ignoreLastSize })" id="clientsEdit">
    <header>
      <h3>Editar Cliente</h3>
    </header>
    <div class="content grid">
      <b-field label="Slug" id="slug">
        <b-input placeholder="Código do cliente" v-model="slug" required></b-input>
      </b-field>
      <b-field label="Usuário cliente" id="clientuser">
        <b-select v-model="clientuserId" placeholder="Selecione um usuário cliente">
          <option value="">- Nenhum -</option>
          <option
            v-for="clientuser in clientusers"
            :value="clientuser.id"
            :key="clientuser.id">
          {{ clientuser.name + ' - ' + clientuser.user.email }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Servidor" id="server">
        <b-select v-model="serverId" placeholder="Selecione um servidor">
          <option
            v-for="server in servers"
            :value="server.id"
            :key="server.id">
          {{ server.name + ' - ' + server.version }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Ativo" id="active">
          <b-checkbox v-model="active" :true-value="1" :false-value="0"></b-checkbox>
      </b-field>
      <b-field label="Token" id="token">
        <b-input placeholder="Token de acesso para verificação N5" v-model="token"></b-input>
      </b-field>
      <b-field label="Ignorar tamanho do último backup" id="ignorelastsize">
        <b-checkbox v-model="ignoreLastSize" :true-value="1" :false-value="0"></b-checkbox>
      </b-field>
      <b-field label="Testes" id="tests">
        <div v-for="(test, index) in tests" :key="index" class="field">
          <b-checkbox v-model="test.pivot.active" :true-value="1" :false-value="0" v-if="test.id !== 5" @input="(test.id === 4 && !$event) ? tests[index + 1].pivot.active = 0 : null">{{ test.test_type.name }}</b-checkbox>
          <b-checkbox v-model="test.pivot.active" :true-value="1" :false-value="0" v-else :disabled="(tests[index - 1].pivot.active !== 1) || !token">{{ test.test_type.name }}</b-checkbox>
        </div>
      </b-field>
      <!--
        Change the editActive on the $parent, closing the edit form
        @event cancel
      -->
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Salvar</button>
    </div>
  </form>
</template>

<script>
/**
 * Edit a client passed as prop
 * @displayName Client Edit
 */
export default {
  name: 'clientsEdit',
  props: {
    /**
     * A client to be edited
     */
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      slug: null,
      token: null,
      backupNumbers: 2,
      tests: [],
      active: 0,
      serverId: 1,
      clientuserId: 0,
      ignoreLastSize: 0
    }
  },
  mounted () {
    this.$store.dispatch('serverStore/index', this)
    this.$store.dispatch('clientuserStore/index', this)
  },
  computed: {
    servers () {
      return this.$store.getters['serverStore/servers']
    },
    clientusers () {
      return this.$store.getters['clientuserStore/clientusers']
    }
  },
  beforeMount () {
    this.slug = this.deepCopy(this.client.slug)
    this.token = this.deepCopy(this.client.token)
    this.tests = this.deepCopy(this.client.tests)
    this.active = this.deepCopy(this.client.active)
    this.serverId = this.deepCopy(this.client.server_id)
    this.clientuserId = this.deepCopy(this.client.clientuser_id)
    this.ignoreLastSize = this.deepCopy(this.client.ignore_last_filesize)
  },
  methods: {
    getUserRoles () {
      this.$http.get(this.$api({
        target: 'roles/creatable'
      })).then(response => {
      })
    },
    deepCopy (attr) {
      return JSON.parse(JSON.stringify(attr))
    }
  }
}
</script>
