const env = process.env.NODE_ENV
export const clientId = '2'

export const clientSecret = (conn) => {
  if (conn === 'prod') {
    return env === 'production' ? '90eaOu7iBKDZmH3yIwVXdT92LB69JHLKMqQxOMIJ' : '90eaOu7iBKDZmH3yIwVXdT92LB69JHLKMqQxOMIJ'
  } else {
    return env === 'production' ? '90eaOu7iBKDZmH3yIwVXdT92LB69JHLKMqQxOMIJ' : '90eaOu7iBKDZmH3yIwVXdT92LB69JHLKMqQxOMIJ'
  }
}
