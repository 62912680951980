<template>
  <b-modal :active.sync="processing.active" :can-cancel="false">
    <circles-to-rhombuses-spinner :animation-duration="1200" :circles-num="3" :circle-size="15" :color="processing.color"/>
  </b-modal>
</template>

<script>
import { CirclesToRhombusesSpinner } from 'epic-spinners'
import { mapGetters } from 'vuex'
export default {
  name: 'processingModal',
  computed: {
    ...mapGetters('applicationStore', [
      'processing'
    ])
  },
  components: {
    CirclesToRhombusesSpinner
  }
}
</script>
