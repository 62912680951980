<script>
const admin = () => ({
  component: import('./admin.vue')
})

const developer = () => ({
  component: import('./developer.vue')
})

const NAVIGATION = {
  admin: admin,
  developer: developer
}

export default {
  route: {
    waitForData: true
  },
  render (h) {
    return h(NAVIGATION[this.$store.getters['userStore/user'] ? this.$store.getters['userStore/user'].role.name : 'developer'])
  }
}
</script>
