<template>
  <main class="page" id="login">
    <l-header></l-header>
    <div class="wrapper">
      <form @submit.prevent="forgotPassword">
        <h3>Redefinição de senha</h3>
        <p>{{ msg }}</p>
        <b-field label="Email">
          <b-input v-model="email" name="email" placeholder="Email" required></b-input>
        </b-field>
        <button :disabled="button.disabled" :class="button.class" type="submit">{{ button.text }}</button>
      </form>
    </div>
  </main>
</template>

<script>
import lHeader from './login/l-header.vue'
import { mapActions } from 'vuex'
export default {
  name: 'forgotPassword',
  data () {
    return {
      email: null,
      button: {
        class: '',
        text: 'Solicitar alteração',
        disabled: false
      }
    }
  },
  methods: {
    ...mapActions('forgotPasswordStore', [
      'get'
    ]),
    forgotPassword () {
      this.get([this, {
        email: this.email, a: 'b'
      }]).then(status => {
        this.msg = 'Um link de recuperação foi enviado para seu e-mail.'
      }).catch(err => {
        console.log(err)
        this.setButton('__shake-horizontal', 'Erro', true)
        setTimeout(() => {
          this.resetButton()
        }, 1000)
      })
    },
    setButton (c, text, disabled) {
      this.button.class = c
      this.button.text = text
      this.button.disabled = disabled
    },
    resetButton () {
      this.button.class = ''
      this.button.text = 'Solicitar alteração'
      this.button.disabled = false
    }
  },
  components: {
    lHeader
  }
}
</script>
