const filters = () => {
  return {
    active: {
      key: 'active',
      label: 'Ativo',
      value: 1,
      active: false
    },
    clientusers: {
      key: 'clientuser_id',
      label: 'Usuário cliente',
      value: null,
      active: false
    },
    servers: {
      key: 'server_id',
      label: 'Servidor',
      value: null,
      active: false
    },
    critical: {
      key: 'critical',
      label: 'Servidor',
      value: 1,
      active: false
    }
  }
}

const state = {
  clients: [],
  clientIndex: 0,
  meta: null,
  filters: filters()
}

const getters = {
  clients: () => state.clients,
  meta: () => state.meta,
  client: () => state.clients.length ? state.clients[state.clientIndex] : null,
  filters: () => state.filters
}

const mutations = {
  SET_CLIENTS (state, clients) {
    if (state.meta) {
      state.clients = [...state.clients, ...clients]
    } else {
      state.clients = clients
    }
  },
  SET_CLIENT_INDEX (state, index) {
    state.clientIndex = index
  },
  SET_CLIENT_RECORD_INDEX (state, index) {
    state.clientRecordIndex = index
  },
  UPDATE_CLIENT (state, client) {
    state.clients.splice(state.clientIndex, 1, client)
  },
  SET_CLIENTS_FILTER (state, payload) {
    const target = payload[0]
    const key = payload[1]
    const value = payload[2]

    state.filters[target][key] = value
    state.meta = null
  },
  SET_CLIENT_META (state, meta) {
    state.meta = meta
  }
}

const actions = {
  setClient ({ commit }, client) {
    commit('SET_CLIENT', client)
  },
  setClientIndex ({ state, commit }, client) {
    const index = state.clients.findIndex(c => c.id === client.id)
    commit('SET_CLIENT_INDEX', index)
    commit('SET_CLIENT_RECORD_INDEX', 0)
  },
  setClientRecordIndex ({ state, commit }, record) {
    const index = state.clients[state.clientIndex].records.findIndex(r => r.id === record.id)
    commit('SET_CLIENT_RECORD_INDEX', index)
  },
  setClientsFilter ({ commit }, payload) {
    commit('SET_CLIENTS_FILTER', payload)
  },
  setClientMeta ({ commit }, meta) {
    commit('SET_CLIENT_META', meta)
  },
  /**
   * Get all clients and sets them on the store
   *
   * @returns Array
   */
  index ({ commit, state }, that) {
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'clients' + (state.meta ? `?page=${state.meta.current_page + 1}` : '')
      })).then(response => {
        const { data: clients, ...meta } = response.data

        commit('SET_CLIENT_INDEX', 0)
        commit('SET_CLIENTS', clients)
        commit('SET_CLIENT_META', meta)
        resolve(response.status)
      }).catch(err => {
        reject(err)
      })
    })
  },

  /**
   * Get all clients with minimal info and returns them
   *
   * @returns Array
   */
  minimal ({ commit }, that) {
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'clients/minimal'
      })).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  show ({ commit }, payload) {
    const that = payload[0]
    const id = payload[1]
    that.$http.get(that.$api({
      target: `client/${id}`
    })).then(response => {
      commit('UPDATE_CLIENT', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  store ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]

    commit('SET_CLIENT_META', null)
    that.$http.post(that.$api({
      target: `client`
    }), data).then(response => {
      const { data: clients, ...meta } = response.data
      commit('SET_CLIENT_INDEX', 0)
      commit('SET_CLIENTS', clients)
      commit('SET_CLIENT_META', meta)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      that.$Progress.fail()
      return err
      // reject(err)
    })
  },
  update ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$Progress.start()
    that.$http.put(that.$api({
      target: `client`
    }), data).then(response => {
      commit('UPDATE_CLIENT', response.data)
      that.$Progress.finish()
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      that.$Progress.fail()
      return err
      // reject(err)
    })
  },
  delete ({ commit }, that) {
    return that
  },
  search ({ commit }, payload) {
    const that = payload[0]
    const slug = payload[1]

    commit('SET_CLIENT_META', null)
    that.$http.get(that.$api({
      target: `client/search/${slug}`
    })).then(response => {
      commit('SET_CLIENT_INDEX', 0)
      commit('SET_CLIENTS', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  filter ({ state, commit }, that) {
    const filters = Object.keys(state.filters)
    let data = {}

    filters.forEach(f => {
      if (state.filters[f].active) data[f] = state.filters[f]
    })

    return new Promise((resolve, reject) => {
      that.$http.post(that.$api({
        target: `clients/filter` + (state.meta ? `?page=${state.meta.current_page + 1}` : '')
      }), data).then(response => {
        const { data: clients, ...meta } = response.data
        commit('SET_CLIENT_INDEX', 0)
        commit('SET_CLIENTS', clients)
        commit('SET_CLIENT_META', meta)
      }).then(status => {
        resolve(status)
      }).catch(err => {
        that.$Progress.fail()
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
