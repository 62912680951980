import moment from 'moment'
moment.locale('pt-BR')

export default {
  methods: {
    /**
     * Def
     *
     * @param {Date} date
     * @public
     */
    parseDate (date) {
      return moment(date).format('DD/MM/YYYY HH:ss')
    }
  }
}
