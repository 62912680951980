<template>
  <main class="page" id="login">
    <l-header></l-header>
    <div class="wrapper">
      <form @submit.prevent="login">
        <b-field label="Email">
          <b-input v-model="username" name="email" placeholder="Email" required></b-input>
        </b-field>
        <b-field label="Senha">
          <b-input type="password" name="password" v-model="password" placeholder="Password" required password-reveal></b-input>
        </b-field>
        <button :disabled="button.disabled" :class="button.class" type="submit">{{ button.text }}</button>
        <p style="text-align:center; margin-top:10px"><router-link to="forgotPassword">Esqueci a senha</router-link></p>
      </form>
    </div>
  </main>
</template>

<script>
import lHeader from './login/l-header.vue'
import { mapActions } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      username: null,
      password: null,
      button: {
        class: '',
        text: 'Entrar',
        disabled: false
      }
    }
  },
  methods: {
    ...mapActions('authStore', [
      'get'
    ]),
    login () {
      this.get([this, {
        username: this.username, password: this.password
      }]).then(status => {
        if (status) {
          this.$store.dispatch('userStore/get', this).then(status => {
            if (status) {
              this.$router.push({ name: 'clients' })
            } else {
              this.setButton('__shake-horizontal', 'Conta Desativada', true)
              this.username = ''
              this.password = ''
              setTimeout(() => {
                this.resetButton()
              }, 5000)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
        this.setButton('__shake-horizontal', 'Erro de Autenticação', true)
        this.password = ''
        setTimeout(() => {
          this.resetButton()
        }, 1000)
      })
    },
    setButton (c, text, disabled) {
      this.button.class = c
      this.button.text = text
      this.button.disabled = disabled
    },
    resetButton () {
      this.button.class = ''
      this.button.text = 'Entrar'
      this.button.disabled = false
    }
  },
  components: {
    lHeader
  }
}
</script>
