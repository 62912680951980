<template>
  <nav class="" id="d-navigation">
    <logo-white></logo-white>
    <render></render>
    <b-button @click="logout" class="__round" size="is-large" icon-left="sign-out-alt"></b-button>
  </nav>
</template>

<script>
import logoWhite from '../../../public/img/logo_min_white.svg'
import { mapActions } from 'vuex'
import render from './d-navigation/render'
export default {
  name: 'd-navigation',
  methods: {
    ...mapActions('authStore', [
      'delete'
    ]),
    logout () {
      this.$router.push({ name: 'login' })
      this.delete()
    }
  },
  components: {
    logoWhite,
    render
  }
}
</script>
