<template>
  <section class="widget" id="clientsList">
    <header class="grid">
      <h3>Clientes</h3>
      <template v-if="activeCommands()">
      <b-field>
        <b-input placeholder="Buscar por cliente" v-model="slug" :disabled="loading"></b-input>
      </b-field>
      <!--
        Change the filterActive on the parent component to true opening the filter selector
        @event filter
      -->
       <b-button @click="$emit('filter')" :class="['__highlight']" size="is-large" icon-left="filter"></b-button>
       <!--
         Change the createActive on the parent component to true opening the form for creating a new client
         @event create
       -->
       <b-button @click="$emit('create')" class="__highlight" size="is-large" icon-left="plus"></b-button>
      </template>
    </header>
    <header v-if="client" class="grid header-selected">
      <p class="table-header">
        <strong>{{ client.slug }}</strong>
      </p>
      <!--
        Change the editActive on the parent component to true opening the form for editing a client
        @event edit
      -->
      <b-button v-if="activeCommands()" @click="$emit('edit')" size="is-large" icon-left="pen"></b-button>
    </header>
    <header class="widget-info" v-if="meta">
      <strong>Quantidade: </strong>{{ meta.total }}
    </header>
    <header class="widget-info" v-else>
      <strong>Quantidade: </strong>{{ clients.length }}
    </header>
    <div ref="clientListContent" class="content" @scroll="listScroll = $event.srcElement.scrollTop">
      <b-table
        :data="clients"
        :paginated="false"
        :selected.sync="client"
        :hoverable="true"
        detailed
        detail-key="id"
        :row-class="(row, index) => parseWarning(row, index)"
        >
        <template slot-scope="props">
          <b-table-column field="slug" label="Nome">
            {{ props.row.slug }}
          </b-table-column>
          <b-table-column  field="lastRecord.record_name" label="Último Backup">
            <span v-if="props.row.lastValidRecord">
              {{ props.row.lastValidRecord.file ? props.row.lastValidRecord.file.record_file_name : '-----' }}
            </span>
            <span v-else>
              -----
            </span>
          </b-table-column>
          <b-table-column field="lastRecord.size.file.file_size" class="__center" label="Tamanho">
            <span v-if="props.row.lastValidRecord && props.row.lastValidRecord.file">
              {{ props.row.lastValidRecord.file ? parseFilesize(props.row.lastValidRecord.file.file_size) : '-----' }}
            </span>
            <span v-else>
              -----
            </span>
          </b-table-column>
          <b-table-column field="server.name" class="__center" label="Servidor">
            {{ props.row.server ? props.row.server.name : '-----' }}
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <b-taglist>
            <b-tag size="is-medium" :type="test.pivot.active ? 'is-success' : 'is-danger'" v-for="(test, index) in props.row.tests" :key="index">
              {{ test.test_type.name }}
            </b-tag>
          </b-taglist>
        </template>
        <template slot="bottom-left">
          <p><b>Total de clientes</b>: {{ clients.length }}</p>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
              <b-icon
                icon="sad-tear"
                size="is-large">
              </b-icon>
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
    <b-modal :active.sync="filterModal">
    </b-modal>
  </section>
</template>

<script>
import { debounce } from 'lodash'
import filesize from 'filesize.js'
import dates from '../../../mixins/dates'
/**
 * Display the list of clients.
 * @displayName Client List
 */
export default {
  name: 'clientsList',
  mixins: [dates],
  props: {
    /**
     * All clients loaded on the clientStore
     */
    clients: {
      type: Array,
      required: true,
      default: () => []
    },
    /**
     * If i'm loading new clients
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      /**
       * @model
       */
      slug: null,
      critical: false,
      filterModal: false,
      listScroll: '0'
    }
  },
  computed: {
    client: {
      get () {
        return this.$store.getters['clientStore/client']
      },
      set (newVal) {
        this.$store.dispatch('clientStore/setClientIndex', newVal)
      }
    },
    meta () {
      return this.$store.getters['clientStore/meta']
    }
  },
  watch: {
    slug: debounce(function (query) {
      if (query === '') {
      /**
       * The search field is cleared, restore the initial state
       */
        this.$emit('clear')
      } else {
      /**
       * There's a new query, find the clients that match
       * @property {String} query - The new query
       */
        this.$emit('find', query)
      }
    }, 500),
    listScroll: debounce(function (scroll, oldScroll) {
      let offsetHeight = this.$refs.clientListContent.offsetHeight
      let height = this.$refs.clientListContent.scrollHeight

      if (height - (offsetHeight + scroll) <= 100) {
        if (!this.slug) {
          /**
           * Hit the bottom of the scroll, get the next page
           */
          this.$emit('paginate')
        }
      }
    }, 100)
  },
  mounted () {
  },
  beforeMount () {
  },
  methods: {
    activeCommands () {
      return this.$parent.activeCommands()
    },
    parseFilesize (size) {
      return filesize(size)
    },
    parseWarning (row, index) {
      if (row.active) {
        if (row.lastRecord && row.lastRecord.tests.length) {
          const results = row.lastRecord.tests.reduce(
            (acc, cur) => acc + cur.result,
            0
          )
          if (results < row.lastRecord.tests.length) {
            return 'is-danger'
          }
        } else {
          return 'is-warning'
        }
      } else {
        return 'is-disabled'
      }
      return ''
    }
  }
}
</script>
