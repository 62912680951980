<template>
  <form @submit.prevent="$emit('filter')" id="clientsFilter">
    <header>
      <h3>Filtrar Clientes</h3>
    </header>
    <div class="content grid">
      <b-field class="filterBox" label="Ativo" id="active">
        <!--
          Change the create on the $parent, closing the create form
          @event input
        -->
        <b-checkbox @input="set('active', 'active', $event)" :value="filters.active.active" :true-value="1" :false-value="0"></b-checkbox>
        <b-field v-if="filters.active.active">
          <b-radio
            @input="set('active', 'value', $event)"
            :value="filters.active.value"
            :native-value="1">
            Ativos
          </b-radio>
          <b-radio
            @input="set('active', 'value', $event)"
            :value="filters.active.value"
            :native-value="0">
            Inativos
          </b-radio>
        </b-field>
      </b-field>
      <b-field class="filterBox" label="Critico" id="critical">
        <b-checkbox @input="set('critical', 'active', $event)" :value="filters.critical.active" :true-value="1" :false-value="0"></b-checkbox>
        <b-field v-if="filters.critical.active">
          <b-radio @input="set('critical', 'value', $event)" :value="filters.critical.value" :native-value="1">
            Críticos
          </b-radio>
          <b-radio @input="set('critical', 'value', $event)" :value="filters.critical.value" :native-value="0">
            Registros Ok
          </b-radio>
        </b-field>
      </b-field>
      <b-field class="filterBox" label="Servidores" id="server">
        <b-checkbox @input="set('servers', 'active', $event)" :value="filters.servers.active" :true-value="1" :false-value="0"></b-checkbox>
        <b-select v-if="filters.servers.active" @input="set('servers', 'value', $event)" :value="filters.servers.value" placeholder="Selecione um servidor">
          <option v-for="server in servers" :value="server.id" :key="server.id">
          {{ server.name + ' - ' + server.version }}
          </option>
        </b-select>
      </b-field>
      <button class="__error" id="cancel" @click="$emit('cancel')">Cancelar</button>
      <button class="__success" id="confirm" type="submit">Filtrar</button>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
/**
 * Edit the filters contained on clientStore
 * @displayName Client filter
 */
export default {
  name: 'clientsFilter',
  computed: {
    servers () {
      return this.$store.getters['serverStore/servers']
    },
    filters () {
      return this.$store.getters['clientStore/filters']
    }
  },
  beforeMount () {
    this.$store.dispatch('serverStore/index', this)
  },
  methods: {
    ...mapActions('clientStore', [
      'setClientsFilter'
    ]),
    /**
     * Call the setClientsFilter action setting new filters

     * @param {String} target - What filter I'm changing
     * @param {String} key - What key of the target filter I'm changing
     * @param {String|Number} value - What's the new value

     * @public
     */
    set (target, key, value) {
      this.setClientsFilter([target, key, value])
    }
  }
}
</script>
