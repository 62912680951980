<template>
  <main class="page" id="login">
    <l-header></l-header>
    <div class="wrapper">
      <form @submit.prevent="resetPassword">
        <h3>Redefinição de senha para {{ email }}</h3>
        <p>{{ msg }}</p>
        <input type="hidden" name="email" :value="email">
        <input type="hidden" name="token" :value="token">
        <b-field label="Nova senha">
          <b-input type="password" name="password" v-model="password" placeholder="Password" required password-reveal></b-input>
        </b-field>
        <b-field label="Repita a senha">
          <b-input type="password" name="password_confirmation" v-model="password_confirmation" placeholder="Password" required password-reveal></b-input>
        </b-field>
        <button :disabled="button.disabled" :class="button.class" type="submit">{{ button.text }}</button>
      </form>
    </div>
  </main>
</template>

<script>
import lHeader from './login/l-header.vue'
import { mapActions } from 'vuex'
export default {
  name: 'resetPassword',
  data () {
    return {
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: null,
      password_confirmation: null,
      msg: null,
      button: {
        class: '',
        text: 'Alterar',
        disabled: false
      }
    }
  },
  methods: {
    ...mapActions('resetPasswordStore', [
      'get'
    ]),
    resetPassword () {
      if (this.password !== this.password_confirmation) {
        this.setButton('__shake-horizontal', 'As senhas não coincidem', true)
        setTimeout(() => {
          this.resetButton()
        }, 1000)
        return
      }

      if (this.password.length < 8) {
        this.msg = 'A senha precisa ter no mínimo 8 caracteres.'
        return
      }

      this.get([this, {
        token: this.token, email: this.email, password: this.password, password_confirmation: this.password_confirmation
      }]).then(status => {
        if (status) {
          this.$router.push({ name: 'login' })
        } else {
          this.setButton('__shake-horizontal', 'Erro', true)
          setTimeout(() => {
            this.resetButton()
          }, 1000)
        }
      }).catch(err => {
        console.log(err)
        this.setButton('__shake-horizontal', 'Erro', true)
        setTimeout(() => {
          this.resetButton()
        }, 1000)
      })
    },
    setButton (c, text, disabled) {
      this.button.class = c
      this.button.text = text
      this.button.disabled = disabled
    },
    resetButton () {
      this.button.class = ''
      this.button.text = 'Entrar'
      this.button.disabled = false
    }
  },
  components: {
    lHeader
  }
}
</script>
