const state = {
  clientusers: [],
  clientuserIndex: 0
}

const getters = {
  clientusers: () => state.clientusers,
  clientuser: () => state.clientusers.length ? state.clientusers[state.clientuserIndex] : null
}

const mutations = {
  SET_CLIENTUSERS (state, clientusers) {
    state.clientusers = clientusers
  },
  SET_CLIENTUSER_INDEX (state, index) {
    state.clientuserIndex = index
  },
  UPDATE_CLIENTUSER (state, clientuser) {
    state.clientusers.splice(state.clientuserIndex, 1, clientuser)
  }
}

const actions = {
  setClientuser ({ commit }, clientuser) {
    commit('SET_CLIENTUSER', clientuser)
  },
  setClientuserIndex ({ state, commit }, clientuser) {
    const index = state.clientusers.findIndex(c => c.id === clientuser.id)
    commit('SET_CLIENTUSER_INDEX', index)
  },
  /**
   * Get all clientusers and return them
   *
   * @returns Array
   */
  index ({ commit }, that) {
    that.$http.get(that.$api({
      target: 'clientusers'
    })).then(response => {
      commit('SET_CLIENTUSERS', response.data)
      // if (response.data.length) {
      //   commit('SET_CLIENTUSER', response.data[0])
      // }
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
    // return new Promise((resolve, reject) => {
    // })
  },
  show ({ commit }, that) {
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'clientusers/profile/' + that.$store.getters['userStore/user'].id
      })).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  store ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$http.post(that.$api({
      target: `clientuser`
    }), data).then(response => {
      commit('SET_CLIENTUSERS', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  update ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$http.put(that.$api({
      target: `clientuser`
    }), data).then(response => {
      commit('UPDATE_CLIENTUSER', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  delete ({ commit }, that) {
    return that
  },
  check ({ commit }, payload) {
    const that = payload[0]
    const email = payload[1]
    return new Promise((resolve, reject) => {
      that.$http.get(that.$api({
        target: 'clientusers/check/' + email
      })).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  profile ({ commit }, that) {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
