<template>
  <div id="dashboard">
    <d-navigation></d-navigation>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import dNavigation from './dashboard/d-navigation.vue'
export default {
  name: 'dashboard',
  components: {
    dNavigation
  }
}
</script>
