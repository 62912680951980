import Vue from 'vue'
import Router from 'vue-router'

import Login from './views/Login.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import ResetPassword from './views/ResetPassword.vue'
import Dashboard from './views/Dashboard.vue'
import DClients from './views/dashboard/d-clients.vue'

const DSettings = () => import('./views/dashboard/d-settings.vue')
const DReports = () => import('./views/dashboard/d-reports.vue')
const DDeveloper = () => import('./views/dashboard/d-developers.vue')
const DClientuser = () => import('./views/dashboard/d-clientusers.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/forgotpassword',
      name: 'forgotPassword',
      component: ForgotPassword
    },
    {
      path: '/resetpassword',
      name: 'resetPassword',
      component: ResetPassword
    },
    {
      path: '/dashboard',
      component: Dashboard,
      children: [
        {
          path: 'clients',
          name: 'clients',
          component: DClients
        },
        {
          path: 'settings',
          name: 'settings',
          component: DSettings
        },
        {
          path: 'reports',
          name: 'reports',
          component: DReports
        },
        {
          path: 'developers',
          name: 'developers',
          component: DDeveloper
        },
        {
          path: 'clientusers',
          name: 'clientusers',
          component: DClientuser
        }
      ]
    }
  ]
})
