import axios from 'axios'
import { header } from '@/config'
import store from '@/store.js'

const token = document.head.querySelector('meta[name="csrf-token"]')
const access_token = localStorage.getItem('access_token')

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

if (access_token) {
    axios.defaults.headers.common['Authorization'] = access_token
}


axios.interceptors.request.use(config => {
  if (config.url.includes('api/v1')) {
    config.headers = header()
    store.dispatch('applicationStore/setLoading', 1)
  }

  if (config.method === 'post') {
    if (['database', 'connection'].indexOf(config.url.split('/').pop()) !== -1) {
      store.dispatch('applicationStore/setProcessingStart')
    }
  }
  // if (config.url.includes('clients')) {
  //   store.dispatch('applicationStore/setProcessingStart')
  // }
  return config
}, err => {
  store.dispatch('applicationStore/setLoading', -1)
  return Promise.reject(err)
})

axios.interceptors.response.use(response => {
  if (response.config.method === 'post') {
    if (['database', 'connection'].indexOf(response.config.url.split('/').pop()) !== -1) {
      store.dispatch('applicationStore/setProcessingFinish', true)
    }
  }
  // if (!response.config.url.includes('clients')) {
  //   store.dispatch('applicationStore/setProcessingFinish', true)
  // }
  store.dispatch('applicationStore/setLoading', 0)
  return response
}, err => {
  store.dispatch('applicationStore/setLoading', -1)
  // if (!err.config.url.includes('clients')) {
  //   store.dispatch('applicationStore/setProcessingFinish', false)
  // }
  return Promise.reject(err)
})

export default axios
