const state = {
  servers: [],
  serverIndex: 0
}

const getters = {
  servers: () => state.servers,
  server: () => state.servers.length ? state.servers[state.serverIndex] : null
}

const mutations = {
  SET_SERVERS (state, servers) {
    state.servers = servers
  },
  SET_SERVER_INDEX (state, index) {
    state.serverIndex = index
  },
  SET_SERVER_RECORD_INDEX (state, index) {
    state.serverRecordIndex = index
  },
  UPDATE_SERVER (state, server) {
    state.servers.splice(state.serverIndex, 1, server)
  }
}

const actions = {
  setServer ({ commit }, server) {
    commit('SET_SERVER', server)
  },
  setServerIndex ({ state, commit }, server) {
    const index = state.servers.findIndex(c => c.id === server.id)
    commit('SET_SERVER_INDEX', index)
    commit('SET_SERVER_RECORD_INDEX', 0)
  },
  setServerRecordIndex ({ state, commit }, record) {
    const index = state.servers[state.serverIndex].records.findIndex(r => r.id === record.id)
    commit('SET_SERVER_RECORD_INDEX', index)
  },
  /**
   * Get all servers and return them
   *
   * @returns Array
   */
  index ({ commit }, that) {
    that.$http.get(that.$api({
      target: 'servers'
    })).then(response => {
      commit('SET_SERVER_INDEX', 0)
      commit('SET_SERVERS', response.data)
      that.isLoading = false
      return true
    }).then(status => {
      return status
    }).catch(err => {
      return err
    })
  },
  show ({ commit }, payload) {
    const that = payload[0]
    const id = payload[1]
    that.$http.get(that.$api({
      target: `server/${id}`
    })).then(response => {
      commit('UPDATE_SERVER', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  store ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]

    that.$http.post(that.$api({
      target: `server`
    }), data).then(response => {
      commit('SET_SERVERS', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  update ({ commit }, payload) {
    const that = payload[0]
    const data = payload[1]
    that.$http.put(that.$api({
      target: `server`
    }), data).then(response => {
      commit('UPDATE_SERVER', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  },
  destroy ({ commit, state }, that) {
    return new Promise((resolve, reject) => {
      that.$http.delete(that.$api({
        target: `server/${state.servers[state.serverIndex].id}`
      })).then(response => {
        commit('SET_SERVERS', response.data)
        commit('SET_SERVER_INDEX', 0)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  search ({ commit }, payload) {
    const that = payload[0]
    const slug = payload[1]

    that.$http.get(that.$api({
      target: `server/search/${slug}`
    })).then(response => {
      commit('SET_SERVER_INDEX', 0)
      commit('SET_SERVERS', response.data)
      return true
    }).then(status => {
      return status
      // resolve(status)
    }).catch(err => {
      return err
      // reject(err)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
